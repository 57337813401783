@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap");

* {
  font-family: "Manrope";
  margin: 0px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.center-absolute {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.nimbleedge-gradient {
  background: linear-gradient(90deg, #6565ff 15%, #e33764 85%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

/* TEXT ANIMATION */

.title-local span,
.subtitle-local span {
  width: 100%;
  float: left;
  transform: translateY(-80px);
  opacity: 0;
  animation-name: titleAnimation;
  animation-timing-function: ease;
  animation-duration: 5s;
}

.title-local span {
  animation-delay: 0.6s;
  -webkit-animation-fill-mode: forwards;
}
.title-local span:first-child {
  animation-delay: 0.7s;
}
.title-local span:last-child {
  animation-delay: 0.5s;
}

.subtitle-local span {
  transform: translateY(-20px);
  animation-delay: 1s;
  -webkit-animation-fill-mode: forwards;
}
.subtitle-local span:first-child {
  animation-delay: 1.5s;
}
.subtitle-local span:last-child {
  animation-delay: 2s;
}

.filler-title-local span {
  width: 100%;
  float: left;
  -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
  clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
  transform: translateY(-20px);
  opacity: 0;
  animation-name: fillerTextAnimation;
  animation-timing-function: ease;
  animation-duration: 1.8s;
  animation-delay: 0.8s;
  -webkit-animation-fill-mode: both;
}

/* SHINY TEXT */
.fade-in-button {
  animation: fade-in 5s;
}

.shiny-button-local {
  overflow: hidden;
  background: linear-gradient(90deg, #000, #fff, #000);
  background-repeat: no-repeat;
  background-size: 80%;
  animation: animate 2s linear infinite,
    fade-in 6s cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.4);
}

@keyframes titleAnimation {
  0% {
    transform: translateY(-20px);
    opacity: 0;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
  }
  20% {
    transform: translateY(0);
    opacity: 1;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
  }
  80% {
    transform: translateY(0);
    opacity: 1;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
    /* -webkit-clip-path: polygon(100% 0, 100% 0%, 0 100%, 0 100%);
    clip-path: polygon(100% 0, 100% 0%, 0 100%, 0 100%); */
  }
}

@keyframes fillerTextAnimation {
  0% {
    transform: translateY(-20px);
    opacity: 0;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 80%);
  }
  20% {
    transform: translateY(0);
    opacity: 1;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
  }
  80% {
    transform: translateY(0);
    opacity: 1;
    -webkit-clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
    clip-path: polygon(100% 0, 100% 100%, 0 100%, 0 15%);
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
    -webkit-clip-path: polygon(100% 0, 100% 0%, 0 100%, 0 100%);
    clip-path: polygon(100% 0, 100% 0%, 0 100%, 0 100%);
  }
}

@keyframes animate {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* scale animation */
.section-1 {
  animation: scale 3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-delay: 0;
}

.section-2 {
  animation: slide-up 3s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.86, 0, 0.07, 1);
  animation-delay: 0.2s;
}

.section-3 {
  animation: de-scale 4s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-delay: 0;
}

.section-4 {
  /* animation: de-scale 4s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-delay: 0; */
  scale: 0.9;
  border-radius: 24px;
}

.expand-to-emulator-screen {
  animation: de-scale-no-opacity 1s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  animation-delay: 0;
}

@keyframes scale {
  0% {
    transform: scale(1);
  }

  25%,
  50%,
  75% {
    transform: scale(0.9);
    border-radius: 24px;
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes de-scale {
  0% {
    transform: scale(0.9);
    opacity: 0;
    border-radius: 24px;
  }

  25%,
  50%,
  75% {
    transform: scale(0.9);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    border-radius: 0px;
  }
}

@keyframes de-scale-no-opacity {
  from {
    transform: scale(0.9);
    border-radius: 24px;
  }
  to {
    transform: scale(1);
    border-radius: 0px;
  }
}

@keyframes slide-up {
  0% {
    transform: translate(0, 100%);
  }

  25%,
  50%,
  75% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(0, -100%);
  }
}

.choice-cell-hidden {
  opacity: 0;
  /* cursor: pointer; */
}

.choice-cell {
  overflow: hidden;
  /* cursor: pointer; */
}

.choice-cell:hover .choice-cell-hidden {
  animation: un-fade 1s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.choice-cell:hover .choice-cell-img {
  animation: hover-cell 1s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

@keyframes hover-cell {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}

@keyframes un-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.q-1 {
  animation-delay: 10s;
  animation: fade 6s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.q-2 {
  animation-delay: 10s;
  animation: un-fade 6s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

.question-switch {
  display: grid;
  grid-template-columns: 1fr;
}

.parent section {
  grid-row-start: 1;
  grid-column-start: 1;
}

/* LOADEr */

.progress-bar {
  position: absolute;
  top: 50%;
  height: 2px;
  background: #313335;
}

.done {
  top: 0;
  height: 100vh;
  width: 100vw;
  transition: all 1s cubic-bezier(0.075, 0.82, 0.165, 1);
  border-radius: 0px;
  /* background-color: #18191b;x */
}

.count {
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  font-weight: 100;
  font-size: 3em;
  margin-top: -1.33em;
  color: #313335;
}

.playground {
}

.emulator {
  max-width: 280px;
}

/* SyntaxHighlighter {
  position: relative;
  margin: 0.2rem;
  font-family: monospace;
  display: block;
  white-space: pre;
} */

/* CODEPAD */
/* code {
  max-width: 20rem;
  color: #eff;
  padding: 1rem 3rem;
  margin: 1rem;
  position: relative;
  border-radius: 0.25rem;
  counter-reset: step;
  counter-increment: step 0;
  transition: background 0.3s;
  outline: none;
}

SyntaxHighlighter {
  position: relative;
  margin: 0.2rem;
  font-family: monospace;
  display: block;
  white-space: pre;
}
code p::before {
  top: 0;
  margin-right: 16px;
  color: #50646d;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  content: counter(step);
  counter-increment: step;
} */

.playground {
  animation: un-fade 1s;
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
}

pre code {
  padding: 12px 8px;
  display: block;
  overflow-x: auto;
  background-color: #18191b;
  border-radius: 12px;
  -webkit-text-size-adjust: none;
}

code span {
  font-family: monospace;
}
